
import {Options, Vue} from 'vue-class-component';
import http from "@/api/http";

@Options({
  data() {
    return {
      info: {
        files_list:[]
      }
    }
  },
  mounted() {
    http.get("/portal/gys/get_fu_wu_shang_ru_zhu_xie_yi").then(da => {
      if (da.code == 1) {
        this.info = da.data
      }
    })
  }
})

export default class yueDuXieYi extends Vue {

}
