<template>
  <div>
    <text>提示：附件只支持图片、word文档、PDF文档</text>
    <div style="height: 10px;"></div>
    <van-row v-for="(vo,index) in ziZhiLength" :key="vo.ran_id">
      <van-col span="6">
        <AddImg :list="[vo]"
                fileType="image/*,.pdf,.doc,.docx,.xml"
                length="1" v-model:url="vo.url"
        ></AddImg>
      </van-col>
      <van-col span="14">
        <van-cell-group>
          <van-field v-model="vo.name" placeholder="请输入">
            <template></template>
          </van-field>
        </van-cell-group>
        <van-cell-group>

        </van-cell-group>
      </van-col>
      <van-col span="4" class="text-right">
        <van-button type="default" size="mini" @click="delZiZhi(index)">删除</van-button>
      </van-col>
    </van-row>
    <van-button type="default" size="mini" @click="addziZhiList">
      <i class="iconfont iconxuanfutianjia"></i>&nbsp;&nbsp;添加入
    </van-button>
  </div>
</template>

<script >
import {Options, Vue} from 'vue-class-component';
import AddImg from "@/components/file/AddImg.vue"
import basis from "@/components/class/basis";
//添加资质图片

export default {
  mixins:[basis],
  name:'AddRuZhiXieYi',
  components: {AddImg},
  props: {
    form: {
      type: Array,
      default() {
        return []
      }
    },
    data: Array
  },
  data() {
    return {
      ziZhiLength: [],//上传几张自制图片
    }
  },
  mounted() {
  },
  methods: {
    //添加一排资质图片
    addziZhiList() {
      this.ziZhiLength.push({
        img: '',
        name: '服务商入驻协议',
        ran_id: this.getId()
      })
    },
    //删除资质图片
    delZiZhi(index) {
      this.ziZhiLength.splice(index, 1)
    },
    //同步传来的val
    tongBu(val) {
      if (this.ziZhiLength.length == 0) {
        this.ziZhiLength = val
      }
    }
  },
  watch: {
    "ziZhiLength": {
      handler(newValue, oldValue) {
        this.$emit("update:form", newValue)
      },
      deep: true
    },
    "form"() {
      this.tongBu(this.form)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

</style>
