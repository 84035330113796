<template>
  <van-field
      :required="required"
      @click="showPicker=true"
      readonly v-model="value"
      :label="title"
      :placeholder="placeholder"
  />
  <van-popup
      round
      v-model:show="showPicker"
      position="bottom"
      :style="{ height: '300px' }"
  >
    <van-picker
        title="请选择"
        :columns="columns"
        @confirm="onConfirm"
        @cancel="showPicker=false"
    />
  </van-popup>
</template>

<script>
import {Options, Vue} from 'vue-class-component';
//一级单选
export default {
  name:'DanXuan',
  props: {
    required:{
      type:[Boolean,String],
      default(){
        return false
      }
    },
    //默认值
    val: {
      type: String,
      default() {
        return ''
      }
    },
    value: {
      type: String,
      default() {
        return ''
      }
    },
    //提示
    placeholder: {
      type: String,
      default() {
        return '请选择'
      }
    },
    //标题
    title: {
      type: String,
      default() {
        return ''
      }
    },
    list: {
      type: [Array, String],
      default() {
        return [
          "第一个",
          "第二个",
          "第三个",
          "第四个",
        ]
      }
    }

  },
  setup() {
  },
  data() {
    return {
      valueData: this.val,
      showPicker: false,
      columns: this.list
    }
  },

  methods: {
    onConfirm(value) {
      this.valueData = value;
      this.showPicker = false;
      this.$emit("update:value", this.valueData)
    }
  },
  watch: {
    "value"() {
      if (this.valueData == '') {
        this.onConfirm(this.value)
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

</style>
