<template>
  <div>
    <div class="form-list">
      <van-form ref="form" @submit="yes">
        <div class="padding-top-15">
          <van-cell-group>
            <van-field
                required
                v-model="formData.gongSi.g_name"
                label="服务商名称"
                placeholder="请输入"
            />
            <van-field
                v-model="formData.gongSi.g_abbreviation"
                label="服务商简称"
                placeholder="请输入"

            />
            <van-field
                required
                :readonly="isAutoMobile"
                v-model="formData.lxr.mobile"
                label="手机号码"
                placeholder="请输入您的手机号码"

            />
            <van-field
                required
                v-model="formData.lxr.code"
                label="验证码"
                placeholder="请输入验证码"
                v-if="!isAutoMobile"
            >
              <template #button>
                <a href="javascript:" @click="getCode(formData.lxr.mobile)">{{ getCodeTitle }}</a>
              </template>
            </van-field>

          </van-cell-group>
        </div>
        <van-collapse v-model="xiangXi">
          <van-collapse-item name="xiang-xi" title="">
            <template #value>详细信息</template>
          </van-collapse-item>
        </van-collapse>
        <div v-if="xiangXi.includes('xiang-xi')">
          <div class="">
            <van-cell-group>
              <FuWuShangType
                  ref="fuWuLeiXing"
                  v-model:id="fws_type_id"
                  v-model:name="formData.FuWuShang.fu_wu_type"
                  v-model:form="formData.fws_type"
                  title="服务类型"/>
              <duo-xuan-address
                  dataType="fu_wu_di_qu"
                  label="服务地区"
                  v-model:form="formData.fws_dy"
                  v-model:name="formData.FuWuShang.fu_wu_di_qu"
                  v-model:id="formData.fws_dy_id"
              />
            </van-cell-group>
          </div>
          <div class="padding-top-15">
            <van-cell-group>
              <van-field v-model="formData.gongSi.tel" label="电话" placeholder="请输入"/>
              <Address
                  dataType="all"
                  ref="diZhi"
                  title="地区"
                  v-model:id="formData.gongSi.g_area_id"
              />
              <van-field v-model="formData.gongSi.g_address" label="详细地址" placeholder="请输入"/>
              <van-field
                  v-model="formData.FuWuShang.bei_zhu"
                  rows="2"
                  label="备注"
                  type="textarea"
                  placeholder="请输入"
                  show-word-limit
              />
            </van-cell-group>
          </div>
          <div class="padding-top-15">
            <van-cell-group>
              <van-field name="uploader"
                         label="营业执照"
                         v-if="formData.FuWuShang.gys_type != '个人'"
              >
                <template #input>
                  <AddImg
                      :disabled="is_page"
                      length="1"
                      ref="yingYeZhiZhaoId"
                      v-model:form="formData.lol_gong_si_image"
                  />
                </template>

              </van-field>
            </van-cell-group>
          </div>
        </div>

        <div class="container" style="margin: 20px 0">
          <h5 class="text-center">
            客服电话 <a :href="`tel:${keFu}`">{{ keFu }}</a>
          </h5>
          <div class="ye-du-xie-yi">
            <div class="ye-du-xie-yi-fu-dong">
              <van-checkbox :disabled="is_page" class="text-center" v-model="tongYi" icon-size="15px" shape="square">
                我已阅读并同意 <a href="javascript:" @click.stop="showPage = true">《云佐平台入驻协议》</a>
              </van-checkbox>
            </div>
          </div>

        </div>

        <div class="form-btn" style="margin-top: 0">
          <van-button replace native-type="submit" block type="primary">
            保存
          </van-button>
        </div>
      </van-form>
    </div>


    <van-popup
        v-model:show="showPage"
        position="bottom"
        round
        :style="{ height: '95%' }"
    >
      <yueDuXieYi v-model:show="showPage" v-if="showPage"/>
    </van-popup>
  </div>
</template>

<script>
import FuWuShangType from "@/components/select/FuWuShangType.vue"
import Address from "@/components/select/Address.vue"
import DanXuan from "@/components/select/DanXuan.vue"
import AddImg from "@/components/file/AddImg.vue"
import AddXieYi from "@/components/list/AddXieYi.vue"
import AddRuZhiXieYi from "@/components/list/AddRuZhiXieYi.vue"
import axios from "@/api/http"
import http from "@/api/http"
import {Toast} from "vant";
import yueDuXieYi from "@/views/open/yueDuXieYi.vue"
import DuoXuanAddress from "@/components/select/DuoXuanAddress.vue";
import yanZhengMa from "@/views/open/yanZhengMa";
import XEUtils from "xe-utils";
import din_shi from "../../api/din_shi";
import {isLogin} from "@/api/cookie";
import {setFws} from "@/api/dataList/userApi";

export default {
  name: 'fuWuShangRuZhu',
  mixins: [yanZhengMa],
  components: {
    DuoXuanAddress,
    yueDuXieYi,
    FuWuShangType,
    Address,
    AddImg,
  },
  props: {
    //配置返回地址
    urlName: {
      type: String,
      default() {
        return 'serviceProvider_list'
      }
    },
    //设置参数其他参数提交
    setData: {
      type: Object,
      default() {
        return {
          shang_ji_type: ''//配置商机类型
        }
      }
    }

  },
  data() {
    return {
      xiangXi: [],
      keFu: "",
      showPage: false,
      tongYi: false,//是否已阅读并同意
      status: true,//是否可以修改
      is_page: false,//页面是否存在 false：阅读 true：填写
      yingYeZhiZhao: '',
      addressId: '',
      fuwuType: '',//服务类型id
      fuwuTypeName: '',//服务类型名称
      formData: {
        gongSi: {
          //公司
          g_name: "",
          g_abbreviation: '',
          g_area_id: '',
          g_address: '',
        },
        FuWuShang: {//服务商
          gys_type: '',
          he_zuo_yi_yuan: '高',
          bei_zhu: ''
        },
        fws_type: '',//服务类型
        fws_dy: '',//服务地区
        lol_gong_si_image: [],//营业执照
        lxr: {
          user_nickname: '',
          mobile: '',
          verification: '',
          tel: ''
        }
      }
    }
  },
  mounted() {
    http.get("/portal/index/get_ke_hu_tel").then(da => {
      if (da.code == 1) {
        this.keFu = da.data.content
      }
    })
    this.getMobiel().then(mobile => {
      this.formData.lxr.mobile = mobile
    })
  },
  methods: {
    async yes() {
      if (!this.tongYi) {
        Toast.fail("请同意云佐平台入驻协议")
        return;
      }
      let data = JSON.parse(JSON.stringify(this.formData));
      data.lol_gong_si_image = XEUtils.map(data.lol_gong_si_image, n => {
        return {
          image_url: n.url,
          gl_name: n.name
        }
      })
      let da = await axios.post("/portal/gys/shenQingFuWuShang", data, {load_success: true}, {
        load_success: true
      })
      if (da?.code == 1) {
        din_shi.success(da.msg).then(() => {
          if (isLogin()) {
            setFws().then(da => {
              this.$router.push({name: 'Index'})
            })
          } else {
            this.$router.push({name: 'Index'})
          }
        })

      }
    }

  }

}
</script>
<style scoped>
.ye-du-xie-yi {
  height: 30px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.ye-du-xie-yi-fu-dong {
}
</style>
