<template>
  <div>
    <van-row v-for="(vo,index) in ziZhiLength" :key="vo.ran_id">
      <van-col span="6">
        <AddImg length="1" :list="[vo]" v-model:url="vo.url"></AddImg>
      </van-col>
      <van-col span="18">
        <van-cell-group>
          <van-field v-model="vo.name" label=""  placeholder="资质名称">
            <template #button>
              <a  @click="delZiZhi(index)">删除</a>
            </template>
          </van-field>
          <van-field v-model="vo.dengJi" label="" placeholder="资质等级"/>
        </van-cell-group>
      </van-col>
    </van-row>
    <van-button size="small" type="default" @click="addziZhiList">
      <i class="iconfont iconxuanfutianjia"></i>&nbsp;&nbsp;添加资质图片
    </van-button>
  </div>
</template>

<script >
import {Options, Vue} from 'vue-class-component';
import AddImg from "@/components/file/AddImg.vue"
import basis from "@/components/class/basis";
//添加资质图片


export default {
  name:'AddXieYi',
  mixins:[basis],
  components:{AddImg},
  props: {
    form:{
      type:Array,
      default(){
        return []
      }
    },
    data: Array
  },
  data(){
    return{
      ziZhiLength:[],//上传几张自制图片
    }
  },
  mounted() {
  },
  methods:{
    //添加一排资质图片
    addziZhiList(){
      this.ziZhiLength.push({
        ran_id:this.getId(),
        img:'',
        name:'',
        dengJi:'',
        rang:new Date().getTime()
      })
    },
    //删除资质图片
    delZiZhi(index){
      this.ziZhiLength.splice(index,1)
    },
    //同步传来的val
    tongBu(val){
      this.ziZhiLength = val
    }
  },
  watch:{
    "ziZhiLength":{
      handler(newValue, oldValue){
        this.$emit("update:form",newValue)
      },
      deep: true
    },
    "form"(){
      this.tongBu(this.form)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

</style>
