<template>
  <van-field v-model="value"
             autosize
             :label-width="labelWidth"
             :required="required"
             rows="1"
             type="textarea"
             @click="show = true"
             :label="label"
             readonly
             is-link
             placeholder="请选择"/>
  <van-popup
      round
      v-model:show="show"
      position="bottom"
  >

    <div class="nei-rong">
      <van-tree-select
          v-model:active-id="activeId"
          v-model:main-active-index="activeIndex"
          :items="items"
          @click-item="leftClick"
      />
      <div class="text-center an-niu">
        <van-button type="primary" @click="yes" size="normal">确定</van-button>
      </div>
      <div style="height: 10px;"></div>
    </div>
  </van-popup>
</template>

<script>
import axios from "@/api/http.js";
import _ from 'lodash'
//多选项目类型
export default {
  name: 'DuoXuanAddress',
  props: {
    labelWidth: Number,
    id: {
      type: String,
      default() {
        return ''
      }
    },
    label: {
      type: String,
      default() {
        return ''
      }
    },
    dataType: {
      type: String,
      default() {
        return 'fu_wu_di_qu'
      }
    },
    required: [Boolean, String]
  },

  data() {
    return {
      show: false,
      dataUrl: {
        xm_type: {
          url: "/portal/index/xiangMuType4",
          getIdItem: '/portal/index/get_nav_name',
          idField: 'id',
          nameField: 'name'
        },
        fu_wu_di_qu: {//服务地区
          url: '/portal/index/get_di_qu',
          getIdItem: "/portal/index/get_id_address",
          idField: 'id',
          nameField: 'name'
        },
        jian_duan_di_qu: {//简短地区
          url: '/portal/index/get_di_qu',
          getIdItem: "/portal/index/get_id_address",
          idField: 'id',
          nameField: 'name'
        },
        jian_duan_er_ji_di_qu:{//获取二级简介地区,没有全部按钮
          url:'/portal/index/jian_duan_er_ji_di_qu',
          getIdItem: "/portal/index/get_id_address",
          idField: 'id',
          nameField: 'name'
        }
      },
      value: [],
      items: [
        // { text: '浙江', children: [], dot: true },
        // { text: '江苏', children: [], badge: 5 },
      ],
      activeId: [],
      activeIndex: 0,
      name: [],
      form_list: [],//表单格式数据
    }
  },

  mounted() {
    axios.get(this.dataUrl[this.dataType].url).then((da) => {
      if (da.code == 1) {
        this.items = da.data
        this.$watch('id',this.jian_ting_id,{
          immediate: true,
        })
      }
    })
  },
  methods: {
    leftClick(data) {
      this.xunHuanXuanZeQuanBu()
    },
    /**
     * 循环每一个 全部
     */
    xunHuanXuanZeQuanBu() {
      _.map(this.items,n=>{
        _.map(n.children,n2=>{
          if (typeof n2.is_all != 'undefined' && n2.is_all == 'yes') {
            this.set_select_all(n2)
          }
        })
      })
    },
    //实现选择全部
    set_select_all(data) {
      let status = this.activeId.indexOf(data.id) != -1 //是否选中 true：选中 false：取消
      this.items.forEach((n, index) => {
        if (n.id == data.parent_id) {
          // console.log(n)
          n.children.forEach((n2, index2) => {
            if (n2.is_all == 'no') {
              if (status) {
                //删除已选的
                let self_index = this.activeId.indexOf(n2.id)
                if (self_index != -1) {
                  this.activeId.splice(self_index, 1)
                }
              }

              this.items[index].children[index2].disabled = status
            }

          })
        }
      })


    },
    yes() {
      if (this.activeId.length == 0) {
        this.event([])
        return
      }
      //获取数据
      axios.get(this.dataUrl[this.dataType].getIdItem, {
        params: {id: this.activeId.join(',')}
      }).then((da) => {
        if (da.code == 1) {
          this.event(da.data)
        }
      })
    },
    event(data) {
      this.$emit("update:form", data)
      this.$emit("update:id", data.map(n => n[this.dataUrl[this.dataType].idField]).join(','))
      this.$emit("update:name", data.map(n => n[this.dataUrl[this.dataType].nameField]).join(','))
      this.value = data.map(n => n[this.dataUrl[this.dataType].nameField]).join('、')
      this.show = false
    },
    jian_ting_id() {
      console.log('id ok')
      if(_.isUndefined(this.id)) return
      this.activeId = _.split(this.id,',').map(n => parseInt(n))
      this.activeId = _.compact(this.activeId)
      this.xunHuanXuanZeQuanBu()
      this.yes()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.an-niu {
  margin-top: 30px;
}
</style>
